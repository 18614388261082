<template>
  <div class="confirm-order">
    <div class="confirm-content">
      <div class="confirm-title">确认订单</div>
      <ul class="my-table">
        <li class="table-th">
          <span class="table-td" style="width: 92px">id</span>
          <span class="table-td" style="width: 288px">产品名称</span>
          <span class="table-td" style="width: 178px">产品类型</span>
          <span class="table-td" style="width: 178px">原价</span>
          <span class="table-td" style="width: 178px">有效期</span>
        </li>
        <li class="table-tr">
          <span class="table-td" style="width: 92px">{{confirmOrder.id}}</span>
          <span class="table-td" style="width: 288px">{{confirmOrder.name}}</span>
          <span class="table-td" style="width: 178px">{{confirmOrder.type}}</span>
          <span class="table-td" style="width: 178px">￥{{confirmOrder.original_price}}</span>
          <span class="table-td" style="width: 178px">{{confirmOrder.expiration_time}}</span>
        </li> 
      </ul>
      <div class="settlement">
        <div class="param-money">应付金额：<span class="money">￥{{confirmOrder.price}}</span></div>
        <div class="button-box">
          <button class="submit-btn" :class="agree==1?'blue':''" @click="subBtn">提交订单</button>
        </div>
        <label class="agree" @click="agree = agree==0?1:0"><div class="check-box" :class="agree==1?'active':''"></div> 我已阅读，并同意《相关条款》</label>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: 'ConfirmOrder',
  data(){
    return {
      confirmOrder: '',
      agree: 0,
      courseId: ''
    }
  },
  mounted(){
    this.getConfirmOrder();//获取信息
  },
  methods: {
    ...mapMutations(['ShowTipModal']), //tip弹窗
    //获取信息
    getConfirmOrder(){
      this.courseId = this.$route.params.id;
      let getUrl = '';
      let data = {};

      if(this.courseId.includes('series')){
        getUrl = '/v1/subject/confirmOrder';
        data.subject_id = this.courseId.split('_')[1];
      }else{
        getUrl = '/v1/course/confirmOrder';
        data.course_id = this.courseId;
      }

      this.$axios.post(getUrl, data, {useLog: true}).then(res => {
        this.confirmOrder = res.data;
      });
    },
    //提交按钮
    subBtn(){
      if(this.agree == 0){
        this.ShowTipModal({
            text: '请勾选《相关条款》',     //提示弹窗内容
            showBtns: true,                //是否显示提示弹窗按钮
          })
      }else{
        let getUrl = '';
        let data = {
          is_read: this.agree
        };

        if(this.courseId.includes('series')){
          getUrl = '/v1/subject/createOrder';
          data.subject_id = this.courseId.split('_')[1];
        }else{
          getUrl = '/v1/course/createOrder';
          data.course_id = this.courseId;
          data.source ='1'
        }

        this.$axios.post(getUrl, data, {useLog: true}).then(res => {
          if(res.code == 1){
            this.ShowTipModal({
              text: res.msg,     //提示弹窗内容
              showBtns: true,    //是否显示提示弹窗按钮
            })
          }else{
            res.data.courseId = this.courseId;
            localStorage.setItem('orderInfo', JSON.stringify(res.data));
            this.$router.push({path: '/cashier'});
          }
        });
      }
    }
  }
}
</script>
<style scoped lang="scss">
.confirm-order{
  background-color: #F7F7F7;
  padding: 64px 0 72px;
}
.confirm-content{
  box-sizing: border-box;
  width: 1200px;
  padding: 34px;
  background-color: #fff;
  margin: 0 auto;
}
.confirm-title{
  font-size: 24px;
  font-weight: 800;
  color: #333;
  margin-bottom: 30px;
}
.my-table{
  margin-bottom: 70px;
}
.settlement{
  .param-money{
    font-size: 12px;
    color: #999;
    margin-bottom: 8px;
    .money{
      font-size: 18px;
      font-weight: 900;
      color: #CD1B14;
    }
  }
}
.submit-btn{
  width: 153px;
  height: 40px;
  line-height: 40px;
  background: #999999;
  border-radius: 6px;
  font-size: 16px;
  color: #FFF;
  cursor: pointer;
  margin-bottom: 10px;
  &.blue{
    background: #254ED4;
    color: #FFFFFF;
  }
}
.agree{
  font-size: 14px;
  color: #999;
}
.param-money,
.button-box,
.agree{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.check-box{
  margin-right: 6px;
}
</style>